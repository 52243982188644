import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './header.css';
import people from '../../assets/people.png';
import mainlogo from '../../assets/Empire_WL_LogoPNG2.png';
import ReCAPTCHA from "react-google-recaptcha";


const Header = () => {
  

  const onChange = () => {

  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_6cjmb11', 
      'template_cqv80bc', 
      form.current, 
      '3z36Kx0DE-eLgU-qO'
      )
      .then((result) => {
          console.log(result.text);
          console.log("Message Sent");
          alert("Message Sent");
          e.target.reset();
      }, (error) => {
          console.log(error.text);
          console.log("Something's Wrong");
          alert("Something went Wrong")
      });
  };

  return (
    <div className="media__header section__padding" id="home">
      <div className="media__header-content">
        <h1 className="gradient__text">Step up your game with Empire Weightlifting</h1>
        <p>
          Become part of a close-knit and vibrant weightlifting community within Central 
          New York. Whether you are a complete beginner or competing at a national level, 
          Empire Weightlifting is the club for you. 
        </p>
           
        <div className="media__header-content__input">
          <form ref={form} onSubmit={sendEmail}>

            <input type="text" name="user_name" placeholder='Name' required/>

            <input type="email" name="user_email" placeholder='Email' required/>

            <input type='text' name="message" placeholder='Message'/>
            <button type="submit">Submit</button>
                <ReCAPTCHA
                  sitekey="6LeVvx8pAAAAAHLm5gWfPO8_Sgt38RhHH6-L3bOY"
                  onChange={onChange}
                  
                />
          </form>
        </div>

        <div className="media__header-content__people">
          <img src={people} alt="people"/>
          <p>Add your PR and acheivements to the many that continue to grow on the team!</p>
        </div>
      </div>
      
      <div className="media__header-image">
        <img src={mainlogo} alt="empireLogo" />
      </div>
    </div>
  )
}

export default Header
