import React from 'react';
import { Article } from '../../components';
import { images } from '../../constants';
import './blog.css';

const Blog = () => {
  return (
    <div className="media__blog section__padding" id="coaches">
      <div className="media__blog-heading">
        <h1 className="gradient__text">Meet our coaches:</h1>
      </div>
      <div className="media__blog-container">

        <div className="media__blog-container_groupB">
          <Article imgUrl={images.head_john} First="John Rivero" Last="" details1="Owner" details2="USAW National Coach"/>
          <Article imgUrl={images.head_justin} First="Justin Barber" Last="" details1="Coach - USAW L1" details2="Photographer"/>
          <Article imgUrl={images.head_gwen} First="Gwen Proper" Last="" details1="Licensed Massage" details2="Therapist"/>
          <Article imgUrl={images.head_celia} First="Celia Ricci" Last="" details1="Coach - USAW L1"  details2="CFL1"/>
          <Article imgUrl={images.head_jack} First="Jack Buis" Last="" details1="Personal Trainer" details2="CFL2"/>
        </div>
      </div>
    </div>
  )
}

export default Blog
