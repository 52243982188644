import anna from '../assets/anna.jpg';
import emily from '../assets/emily.jpg';
import jack from '../assets/jack.jpg';
import celia from '../assets/celia.jpg';
import main from '../assets/main.png';
import jamie from '../assets/jamie.jpg';
import justin from '../assets/justin.jpg';
import bar from '../assets/Empire_WL_barONLY.png';
import justin1 from '../assets/justin1.jpg';
import justin2 from '../assets/justin2.jpg';
import alle1 from '../assets/alle1.jpg';
import arkady1 from '../assets/arkady1.jpg';
import emily2 from '../assets/emily2.jpg';
import gwen from '../assets/gwen.jpg';
import josh from '../assets/josh.jpg';
import nat1 from '../assets/nat1.jpg';
import team from '../assets/team.jpg';
import head_celia from '../assets/head_celia.jpg';
import head_gwen from '../assets/head_gwen.jpg';
import head_jack from '../assets/head_jack.jpg';
import head_john from '../assets/head_john.jpg';
import head_justin from '../assets/head_justin.jpg';
import medals from '../assets/medals.jpg';
import medals2 from '../assets/medals2.jpg';
import medals3 from '../assets/medals3.jpg';

export default {
    anna,
    emily,
    jack,
    celia,
    main,
    jamie,
    bar,
    justin,
    justin1,
    justin2,
    alle1,
    arkady1,
    emily2,
    gwen,
    josh,
    nat1,
    team,
    head_celia,
    head_gwen,
    head_jack,
    head_john,
    head_justin,
    medals,
    medals2,
    medals3
};
