import React from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

//import { SubHeading } from '../../components';
import { images } from '../../constants';

import './gallery.css';

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center" id="gallery">
      <div className="app__gallery-content">
        <h1 className="gradient__text">Photo Gallery</h1>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[images.main, images.medals, images.celia, images.team, images.anna, images.nat1, images.jack, images.medals2, images.jamie, images.emily2, images.justin1, images.alle1, images.gwen, images.arkady1, images.josh, images.medals3].map((image, index) => (
            <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
              <img src={image} alt="gallery_image" />
              <a href="https://instagram.com/empireweightlifting?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer"><BsInstagram className="gallery__image-icon"/></a>
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  )
}

export default Gallery