import React from 'react';
import Marquee from "react-fast-marquee";

import './ticker.css';

const ticker = () => {
  return (
    <div className="ticker">
      {/* <div className="title">
        <h1>NEWS</h1>
      </div> */}

      <div className="rfm-marquee-container">
        <Marquee direction="left" speed={70} delay={5}>
          <div className="image_wrapper">
            <p><a href="https://www.youtube.com/live/Y5RX9pWxk8Y?feature=shared" target="_blank" rel="noopener noreferrer">PAN AMS - Mark Lado - 5/31 @ 3:30PM<strong>WATCH NOW</strong></a></p>
          </div>
          {/* <div className="image_wrapper">
            <p><a href="https://instagram.com/alteredstatephotography?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">Justin Barber - 73B - Lifting Friday at 12:05 <strong>WATCH NOW</strong></a></p>
          </div>
          <div className="image_wrapper">
            <p><a href="https://instagram.com/alteredstatephotography?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">Gwen Proper - 59B - Lifting Friday at 2:00 <strong>WATCH NOW</strong></a></p>
          </div>
          <div className="image_wrapper">
            <p><a href="https://instagram.com/alteredstatephotography?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">Jack Buis - 81B - Lifting Friday at 4:00 <strong>WATCH NOW</strong></a></p>
          </div>
          <div className="image_wrapper">
            <p><a href="https://instagram.com/alteredstatephotography?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">Natalie Hart - 71C - Lifting Saturday at 10:15 <strong>WATCH NOW</strong></a></p>
          </div>
          <div className="image_wrapper">
            <p><a href="https://instagram.com/alteredstatephotography?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">Jamie Battaglia - 89B - Lifting Saturday at 4:00 <strong>WATCH NOW</strong></a></p>
          </div> */}
        </Marquee>
      </div>
    </div>
  )
}

export default ticker
