import React from 'react';
import { Feature } from '../../components';
import './whatMedia.css';

const WhatMedia = () => {
  return (
    <div className="media__whatmedia section__margin" id="about">
      <div className="media__whatmedia-feature">
        <Feature title="About Us" text="Empire Weightlifting was started by John Rivero, who is a nationally ranked weightlifter and the head coach of the club.
                                        In 2021, he pioneered an Olympic Weightlifting program at his local Crossfit gym. Under his guidance and expertise, members 
                                        began to grow their confidence and improve their skills, and eventually won medals at local weightlifting competitions. As 
                                        their achievements became recognized, more members joined and the club flourished. In 2022, Empire Weightlifting moved to a 
                                        different Crossfit gym and currently operates within Smokey Hollow Crossfit in Baldwinsville, NY."/>
      </div>
      <div className="media__whatmedia-heading">
        <h1 className="gradient__text">Today I will do what others won't, so tomorrow I can do what others can't. <br/> -Pyrros Dimas-</h1>
        <p><a href="#home">Contact Us Today</a></p>
      </div>
      <div className="media__whatmedia-container">
      <Feature title="Beginning the journey" text="Whether you are new to the sport of Olympic Weightlifting, regardless of your age or prior experience, our coaches will
                                                 work with you to ensure development of strong fundamental skills. Good movement mechanics and lifting techniques
                                                 are a prime focus, and teaching occurs in a friendly and supportive environment." />

      <Feature title="Consistency/Proficiency" text="As you progress through the next step of your lifting journey, Empire Weightlifting will strive for consistency in your
                                                    movements and continued growth in your lifting mechanics. A stable foundation generates strong athletes!" />

      <Feature title="Strength" text="Finally, the athlete’s favorite: strength. With your foundation skills mastered and proficiency dialed in, our Empire
                                      coaches will help you create the necessary strength to set new personal records and progress as an Olympic weightlifter." />
      </div>
    </div>
  )
}

export default WhatMedia
