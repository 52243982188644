import React from 'react'
//add img back under p tag when bar png narrowed down
import { images } from '../../constants';

const SubHeading = ({ title }) => {
  return (
  <div style={{ marginBottom: '1rem' }}>
    <p className="fontMain">{title}</p>
    <img src={images.bar} alt="bar_image" className="bar__img" />
  </div>
  )
}

export default SubHeading