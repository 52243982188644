import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/Empire_WL_LogoPNG.png'
import './navbar.css';

//BEM -> Block Element Modifier 

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="media__navbar">
      <div className="media__navbar-links">
        <div className="media__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="media__navbar-links_container">
          <p><a href="#home">Home</a></p>
          <p><a href="#gallery">Gallery</a></p>
          <p><a href="#about">About Us</a></p>
          <p><a href="#programs">Programs</a></p>
          <p><a href="#possibility">Possibilities</a></p>
          <p><a href="#coaches">Coaches</a></p>
          <p><a href="#contact">Contact Us</a></p>
        </div>
      </div>
      
      {//ADD BACK WHEN USER LOGIN FINISHED
      /* <div className="media__navbar-sign">
        <p>Sign in</p>
        <button type="button">Sign up</button>
      </div> */}
      <div className="media__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="media__navbar-menu_container scale-up-center">
          <div className="media__navbar-menu_container-links">
          <p><a href="#home">Home</a></p>
          <p><a href="#gallery">Gallery</a></p>
          <p><a href="#about">About Us</a></p>
          <p><a href="#programs">Programs</a></p>
          <p><a href="#possibility">Possibilities</a></p>
          <p><a href="#coaches">Coaches</a></p>
          <p><a href="#contact">Contact Us</a></p>
          </div>
          {//ADD BACK WHEN USER LOGIN FINISHED
          /* <div className="media__navbar-menu_container-links-sign">
            <p>Sign in</p>
            <button type="button">Sign up</button>
          </div> */}
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar
