import React from 'react';
import logo from '../../assets/Empire_WL_LogoPNG.png'
import { FiInstagram } from 'react-icons/fi';
import './footer.css';

import { images } from '../../constants';

const Footer = () => {
  return (
  <div className="app__footer section__padding" id="contact">

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">80 Smokey Hollow Rd Unit 2, Baldwinsville, NY 13027</p>
        <p className="p__opensans">johnrivero@empireweightlifting.com</p>
        <p className="p__opensans">+1 570-762-1620</p>

      </div>

      <div className="app__footer-links_logo">
        <img src={logo} alt="footer_logo" />
        <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
        <img src={images.bar} alt="spoon" className="bar" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href="https://instagram.com/empireweightlifting?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer"><FiInstagram /></a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Hours of Operation</h1>
        <p className="p__opensans">Monday - Friday:</p>
        <p className="p__opensans">5:00 am - 7:30 pm</p>
        <p className="p__opensans">Saturday:</p>
        <p className="p__opensans">8:00 am - 10:30 am</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2021 Empire Weightlifting. All Rights reserved.</p>
    </div>

  </div>
  )
}

export default Footer
