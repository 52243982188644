import React from 'react';
import { Feature } from '../../components';
import './features.css';

const featuresData = [
  {
    title: 'Class Program ',
    text: 'Lift with everyone in the gym! A well rounded program that is a great fit for new, novice and intermediate lifters to become more acclimated and consistent with their lifts. Posted weekly'
  },
  {
    title: "Competiter's Program",
    text: 'Monthly cycles tailored to your specific needs as an athlete. This program will attack weaknesses and continue to build upon your strengths. Achieve the highest level possible with our competitive program and own the platform.'
  },
  {
    title: 'Competition Prep',
    text: 'Got a weightlifting meet coming up? Say no more! Prepare like a pro with an expertly crafted 4-week program designed to have you in peak performance on competition day.'
  },
  {
    title: 'Remote Coaching',
    text: 'Your personalized monthly program that can be done anywhere in the world. With comprehensive video reviews and coach check-ins, it keeps your lifts progressing wherever you live and lift.'
  }
]

const Features = () => {
  return (
    <div className="media__features section__padding" id="programs">
      <div className="media__features-heading">
        <h1 className="gradient__text">Which program best suits your needs?</h1>
        <p><a href="#home">Sign up now to get started</a></p>
      </div>  
      <div className="media__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index}/>
        ))}
      </div>
    </div>
  )
}

export default Features
