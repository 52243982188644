import React from 'react';
import './article.css';

const Article = ({ imgUrl, details1, details2, First, Last }) => {
  return (
    <div className="media__blog-container_article">
      <div className="media__blog-container_article-image">
        <img src={imgUrl} alt="blog" />
      </div>
      <div className="media__blog-container_article-content">
        <div>
          <h3>{First}</h3>
          <h3>{Last}</h3>
        </div>
          <p>{details1}<br/>{details2} </p>
      </div>
    </div>
  )
}

export default Article
